.btn {
    box-shadow: none !important;
}

.dial-pad {
    background-color: #375a7f;
}

.minified-row {
    font-size: 12px;
}

.cursor {
    cursor: pointer;
}